import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 809.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
					<path d="M3735 7434 c-122 -18 -260 -50 -346 -80 -580 -202 -978 -701 -1050
-1314 -14 -127 -7 -358 16 -485 35 -195 131 -427 246 -596 235 -344 578 -570
999 -660 91 -20 135 -23 330 -23 196 0 239 3 330 23 330 71 572 201 805 435
224 225 348 448 423 761 25 102 26 126 27 345 0 189 -4 253 -18 325 -65 317
-211 589 -437 815 -174 174 -364 292 -598 374 -170 59 -290 78 -507 81 -104 2
-203 1 -220 -1z m498 -43 c596 -120 1068 -563 1210 -1136 40 -161 50 -254 43
-420 -7 -191 -8 -199 -25 -218 -10 -13 -10 -17 -1 -17 9 0 9 -4 2 -13 -12 -14
-26 -55 -67 -192 -15 -49 -30 -92 -34 -95 -4 -3 -24 -39 -44 -80 -20 -41 -42
-84 -49 -95 -61 -100 -108 -165 -136 -190 -68 -60 -97 -92 -121 -131 -47 -77
-238 -218 -406 -299 -166 -80 -289 -117 -384 -116 -37 0 -142 -4 -236 -9 -141
-8 -179 -7 -225 5 -37 10 -77 13 -120 9 -78 -8 -171 15 -311 75 -214 93 -462
269 -490 349 -5 16 -33 45 -62 66 -48 35 -187 209 -187 234 0 6 -3 12 -8 14
-11 4 -96 192 -120 263 -11 33 -30 92 -43 130 -12 39 -26 72 -29 75 -14 11
-25 142 -24 300 1 182 19 297 75 465 182 546 670 952 1249 1040 138 21 403 14
543 -14z"/>
					<path d="M3773 7304 c-18 -9 -49 -36 -70 -60 -21 -25 -63 -73 -94 -107 -93
-104 -169 -194 -169 -199 0 -3 -22 -38 -49 -79 -61 -94 -86 -140 -82 -158 1
-7 -2 -10 -7 -7 -13 8 -24 -36 -12 -48 13 -13 13 -29 0 -21 -6 4 -7 -2 -1 -17
9 -25 55 -43 206 -84 101 -27 268 -54 333 -54 37 0 42 3 42 23 0 43 11 66 28
60 10 -3 13 -1 7 4 -6 4 -11 22 -11 38 -1 17 -7 150 -13 296 -11 261 -5 369
19 377 5 2 5 12 -1 25 -13 28 -80 34 -126 11z m75 -445 l-3 -364 -65 3 c-86 4
-241 36 -357 73 l-94 30 50 96 c60 113 136 221 231 327 88 99 222 218 232 208
5 -5 7 -172 6 -373z"/>
					<path d="M4206 7309 c3 -5 -6 -9 -20 -9 -14 0 -26 -3 -26 -7 0 -3 36 -39 80
-80 136 -127 257 -290 356 -480 31 -59 58 -111 61 -115 8 -14 301 146 360 196
1 1 0 20 -4 42 -5 31 -20 52 -76 107 -135 129 -335 247 -531 312 -100 33 -212
52 -200 34z m165 -98 c195 -66 375 -172 518 -307 45 -42 81 -79 81 -83 0 -13
-269 -170 -292 -171 -4 0 -16 20 -27 45 -60 137 -203 344 -322 470 -39 41 -65
75 -57 75 8 0 52 -13 99 -29z"/>
					<path d="M3503 7295 c-171 -46 -390 -161 -522 -273 -107 -91 -112 -97 -122
-141 -5 -24 -8 -45 -7 -46 125 -88 304 -195 327 -195 5 0 35 48 67 108 101
190 210 335 356 472 62 59 79 87 43 74 -7 -3 -16 -1 -20 5 -8 15 -59 13 -122
-4z m-5 -137 c-128 -145 -225 -284 -297 -426 l-31 -62 -58 32 c-77 43 -187
115 -201 132 -26 31 222 223 395 307 70 34 232 96 257 98 4 1 -25 -36 -65 -81z"/>
					<path d="M3963 7299 c-7 -7 -13 -25 -13 -39 0 -14 6 -32 13 -39 9 -9 12 -67
12 -219 0 -114 -3 -214 -6 -222 -4 -8 -10 -66 -14 -129 -7 -104 -6 -114 9
-108 13 5 16 -1 16 -35 l0 -41 108 6 c138 8 308 42 459 92 9 3 10 11 4 28 -6
15 -6 28 0 34 15 15 -9 78 -63 166 -83 136 -124 189 -243 315 -38 41 -72 78
-74 84 -2 6 -25 33 -51 60 -41 45 -52 51 -96 55 -32 3 -53 0 -61 -8z m130
-141 c143 -127 298 -328 381 -495 20 -39 36 -74 36 -77 0 -18 -284 -81 -419
-92 l-81 -7 0 170 c0 93 -3 258 -7 366 -5 156 -4 197 7 197 7 0 44 -28 83 -62z"/>
					<path d="M2759 6777 c-78 -52 -227 -334 -249 -469 -5 -32 -12 -63 -16 -69 -4
-7 -8 -35 -10 -63 -2 -28 -7 -100 -11 -159 -5 -60 -6 -110 -4 -113 8 -7 561
-4 561 4 1 4 5 41 11 82 6 41 10 86 9 100 0 35 37 246 54 300 24 76 46 121 56
115 18 -11 23 24 7 48 -8 12 -52 42 -98 66 -76 40 -182 108 -250 161 l-26 20
-34 -23z m258 -218 c88 -48 92 -51 83 -75 -37 -96 -79 -319 -89 -464 l-6 -95
-255 0 -254 0 3 50 c12 212 103 471 232 662 l49 72 72 -50 c40 -28 114 -73
165 -100z"/>
					<path d="M4979 6712 c-47 -31 -132 -81 -188 -111 -57 -30 -111 -61 -120 -70
-9 -9 -34 -16 -56 -17 -22 0 -65 -8 -95 -17 -101 -31 -264 -66 -355 -76 -91
-10 -401 -7 -472 4 -132 22 -260 51 -337 77 -51 16 -105 28 -125 26 -40 -3
-38 1 -62 -148 -7 -41 -18 -88 -25 -105 -13 -30 -22 -86 -24 -160 -1 -28 -4
-34 -9 -22 -4 12 -7 -3 -8 -37 -1 -31 2 -54 7 -51 4 3 7 -3 6 -13 0 -9 5 -16
12 -14 8 1 12 -11 12 -38 l0 -40 369 0 c344 0 370 1 374 18 4 13 5 11 6 -5 0
-13 -4 -23 -10 -23 -5 0 -8 -4 -5 -9 15 -24 -98 -30 -724 -36 -599 -6 -666 -9
-692 -33 -20 -17 -24 -58 -7 -64 6 -2 23 -66 38 -143 43 -211 104 -361 217
-530 88 -133 69 -127 177 -57 52 34 137 82 188 107 68 33 93 50 91 62 -2 9 2
19 8 23 20 12 14 72 -19 195 -18 66 -30 131 -28 144 5 27 -1 53 -12 47 -3 -2
-7 17 -8 43 -1 30 1 39 6 26 5 -11 13 -21 20 -21 21 -3 22 -4 11 -23 -6 -12
-6 -22 -1 -25 5 -4 12 0 15 6 4 7 4 -9 1 -37 -3 -31 -1 -45 4 -36 8 12 19 -23
27 -84 0 -5 3 -17 6 -25 3 -8 16 -46 28 -85 12 -38 29 -82 36 -97 l14 -26 52
19 c125 45 395 98 505 99 28 0 45 5 51 16 8 15 10 15 15 -1 10 -25 -9 -53 -40
-59 -16 -3 -65 -10 -111 -16 -161 -19 -403 -84 -446 -119 -6 -5 -29 -11 -51
-12 -22 -2 -42 -6 -45 -9 -4 -6 -74 -46 -140 -82 -136 -74 -171 -102 -189
-150 -11 -31 -19 -22 106 -131 153 -134 348 -237 562 -298 133 -37 142 -37
106 1 -23 24 -27 33 -16 39 15 10 -3 42 -52 96 -55 59 -159 199 -214 286 -72
116 -84 169 -38 169 2 0 17 -24 35 -52 58 -94 144 -222 170 -252 88 -104 195
-211 295 -296 l40 -34 6 55 c4 29 12 83 18 119 6 36 15 92 19 125 l9 60 9 -45
9 -45 6 40 c4 28 7 13 8 -52 1 -88 15 -125 24 -65 2 15 5 -17 6 -70 1 -73 4
-98 14 -98 24 0 208 177 317 306 37 43 144 203 168 250 25 49 50 76 63 69 11
-7 12 -65 1 -65 -4 0 -14 -15 -21 -32 -40 -92 -171 -281 -250 -363 -44 -46
-75 -101 -65 -116 3 -6 -4 -21 -17 -35 -22 -24 -22 -24 -2 -24 29 0 197 45
263 71 168 64 311 154 465 288 89 78 94 84 89 114 -9 48 -50 78 -254 185 -47
25 -87 49 -88 53 -2 5 -9 6 -15 2 -6 -4 -8 -1 -5 8 5 12 -2 14 -28 11 -21 -3
-77 10 -146 32 -125 39 -234 61 -381 75 -54 6 -105 15 -113 22 -16 13 -19 59
-4 59 6 0 10 -7 10 -15 0 -12 14 -15 70 -15 93 0 260 -25 395 -60 61 -16 116
-30 124 -32 15 -4 60 115 87 227 13 55 21 72 33 70 9 -1 16 -9 15 -18 -3 -58
-5 -68 -14 -62 -6 4 -10 -11 -10 -39 0 -24 -4 -55 -9 -68 -15 -41 -18 -123 -3
-121 10 3 16 -8 14 -28 -1 -3 35 -20 78 -38 43 -18 134 -66 201 -107 68 -41
126 -71 129 -67 4 5 27 37 52 73 100 142 187 354 223 540 9 47 18 95 21 107 2
12 10 28 17 35 22 24 19 63 -5 76 -37 19 -82 21 -588 27 -673 8 -775 12 -809
30 -31 16 -46 41 -30 50 5 4 8 1 7 -7 -2 -11 64 -13 357 -13 l360 0 3 37 c2
26 7 35 17 32 7 -3 19 4 26 17 9 17 9 24 0 27 -6 2 -11 27 -11 61 0 31 -5 62
-12 69 -9 9 -9 12 1 12 9 0 10 4 3 13 -5 6 -13 35 -17 62 -4 28 -10 55 -14 60
-16 21 -32 155 -21 175 6 11 14 20 17 20 3 0 4 -3 1 -6 -4 -3 2 -14 11 -23 10
-10 29 -65 43 -122 25 -100 30 -129 44 -264 3 -33 9 -85 13 -115 l6 -55 290 0
290 0 3 38 c2 21 -4 95 -13 165 -9 70 -17 135 -17 145 1 9 -2 16 -6 13 -4 -2
-8 13 -9 34 -2 33 -15 75 -48 155 -5 11 -9 23 -9 27 -1 3 -4 10 -8 14 -5 4 -8
16 -8 26 0 11 -4 16 -10 13 -5 -3 -10 0 -10 7 0 21 -90 170 -121 201 -16 15
-37 27 -46 27 -10 -1 -57 -27 -104 -58z m178 -134 c68 -114 121 -236 152 -346
24 -88 55 -278 48 -297 -5 -11 -53 -13 -264 -12 l-257 2 -13 125 c-14 138 -34
251 -64 351 -10 36 -19 71 -19 77 0 6 38 29 84 52 46 23 121 66 167 96 46 29
89 54 95 54 6 0 38 -46 71 -102z m-1811 -158 c88 -31 223 -59 369 -79 l130
-17 0 -199 0 -200 -339 -3 -339 -2 7 94 c12 156 75 426 101 426 6 0 38 -9 71
-20z m1243 -32 c39 -125 90 -421 77 -454 -4 -12 -62 -14 -331 -14 l-325 0 0
205 0 205 44 0 c81 0 271 29 382 59 141 37 140 37 153 -1z m-1579 -665 c1
-102 49 -352 92 -476 11 -32 18 -60 17 -61 -2 -1 -44 -24 -94 -51 -49 -26
-121 -68 -159 -92 -38 -24 -72 -41 -75 -36 -115 162 -172 269 -219 408 -31 92
-72 272 -72 319 l0 26 255 0 255 0 0 -37z m838 -165 l-3 -203 -90 -7 c-116
-10 -286 -42 -387 -74 -44 -14 -81 -24 -83 -22 -1 2 -14 39 -28 83 -43 131
-56 196 -82 383 l-5 42 340 0 340 0 -2 -202z m812 163 c0 -86 -78 -432 -100
-446 -5 -3 -42 4 -82 15 -96 26 -298 60 -358 60 -26 0 -62 3 -79 6 l-31 7 0
198 0 199 325 0 325 0 0 -39z m696 17 c-3 -13 -8 -45 -11 -73 -16 -126 -69
-291 -137 -429 -35 -69 -126 -216 -134 -216 -2 0 -32 18 -66 41 -35 22 -111
64 -171 92 -90 44 -107 55 -103 72 2 11 18 74 36 140 17 66 38 182 48 258 l16
137 263 0 263 0 -4 -22z m-1508 -900 c1 -242 -1 -348 -8 -348 -21 0 -265 255
-323 337 -59 83 -157 242 -157 255 0 24 379 109 465 104 20 -1 20 -9 23 -348z
m339 337 c124 -17 283 -55 302 -71 7 -6 -90 -170 -154 -259 -70 -97 -220 -263
-285 -315 l-40 -32 0 346 0 346 38 0 c20 0 83 -7 139 -15z m602 -195 c64 -34
127 -70 141 -81 l25 -20 -69 -65 c-85 -80 -219 -170 -340 -229 -89 -44 -226
-95 -253 -95 -7 0 23 42 67 93 97 110 200 262 255 374 22 45 44 82 49 82 4 1
61 -26 125 -59z m-1559 -37 c78 -142 156 -253 273 -385 32 -38 57 -68 55 -68
-3 0 -36 11 -75 24 -174 59 -381 180 -503 293 l-61 56 53 35 c40 27 205 121
215 122 0 0 20 -35 43 -77z"/>
					<path d="M4761 6094 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
					<path d="M4763 6034 c-3 -8 -1 -20 6 -27 8 -8 11 -4 11 16 0 30 -7 35 -17 11z" />
					<path d="M4685 5510 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
					<path d="M4671 5484 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
					<path d="M2021 4169 c-77 -15 -111 -64 -113 -162 -1 -77 10 -95 80 -138 68
-41 88 -69 71 -97 -7 -12 -24 -26 -37 -32 -21 -9 -28 -6 -58 25 -39 42 -58 46
-96 21 -47 -31 -32 -100 31 -139 28 -17 51 -22 106 -22 80 1 128 20 153 64
l15 28 41 -43 c45 -47 99 -62 174 -49 92 15 134 69 136 175 1 76 -22 118 -82
149 -48 25 -84 64 -77 83 3 8 17 21 31 28 22 12 27 11 52 -13 34 -33 62 -34
90 -4 29 30 28 56 -3 89 -32 35 -58 41 -146 36 -67 -4 -73 -6 -105 -41 -25
-27 -34 -47 -36 -74 -5 -121 1 -132 89 -190 81 -52 90 -88 31 -119 -29 -15
-30 -15 -65 22 -40 43 -65 49 -90 22 -25 -28 -31 -22 -31 32 0 62 -17 90 -85
133 -28 19 -59 42 -67 51 -13 17 -12 21 13 42 35 30 38 30 67 -1 13 -14 33
-25 45 -25 27 0 65 40 65 69 0 25 -43 69 -74 76 -55 10 -84 11 -125 4z m118
-33 c40 -21 57 -44 43 -58 -7 -7 -12 -8 -12 -2 0 5 -8 11 -17 12 -10 1 -39 5
-65 8 -41 5 -50 3 -72 -20 -46 -45 -34 -83 43 -135 31 -21 63 -47 72 -57 33
-38 34 -134 2 -134 -9 0 -13 -6 -10 -14 4 -10 1 -13 -9 -9 -7 3 -14 1 -14 -4
0 -27 -99 -57 -139 -41 -14 5 -35 12 -46 14 -19 4 -45 30 -45 44 0 4 10 15 21
26 18 16 23 16 27 5 20 -57 97 -79 145 -41 48 38 45 100 -7 150 -15 14 -40 40
-58 58 -17 17 -37 32 -45 32 -20 0 -21 86 -1 123 26 50 128 73 187 43z m351
-8 c42 -29 53 -54 19 -44 -13 4 -47 10 -76 13 -46 5 -54 3 -77 -20 -49 -49
-29 -92 73 -160 77 -51 90 -141 28 -188 -17 -13 -40 -29 -52 -36 -24 -15 -121
-17 -130 -3 -4 6 -10 8 -14 5 -5 -2 -19 7 -32 21 l-23 24 23 23 c21 21 23 21
32 4 36 -64 102 -76 150 -28 38 39 39 82 2 119 -15 15 -27 30 -25 35 1 4 -2 7
-7 7 -5 0 -27 18 -49 40 -21 22 -42 37 -47 32 -4 -4 -5 1 -2 10 3 10 2 20 -4
24 -13 7 -2 60 19 92 36 55 133 70 192 30z"/>
					<path d="M3073 4170 c-77 -18 -113 -69 -113 -160 0 -70 20 -102 90 -145 74
-45 87 -76 46 -112 -31 -27 -52 -21 -87 26 -21 29 -40 32 -76 13 -22 -12 -23
-11 -23 30 0 24 -7 53 -15 65 -14 20 -13 25 6 58 17 29 21 47 17 97 -4 53 -9
65 -36 89 -32 28 -35 29 -147 29 l-114 0 -6 -32 c-17 -99 -65 -442 -65 -463
l0 -25 120 0 c130 0 175 11 211 52 16 18 21 20 26 9 22 -54 104 -87 188 -76
104 13 145 63 145 178 0 77 -14 99 -101 158 -65 44 -74 69 -34 95 23 15 27 15
50 0 14 -9 25 -21 25 -26 0 -15 38 -12 65 6 42 27 30 91 -23 118 -36 18 -109
26 -149 16z m144 -52 c26 -27 24 -60 -2 -38 -8 6 -38 14 -67 17 -51 5 -56 4
-80 -26 -26 -30 -30 -76 -10 -91 4 -3 36 -24 70 -48 71 -49 82 -65 82 -122 0
-74 -77 -134 -166 -128 -84 5 -131 43 -98 80 17 18 18 17 50 -17 25 -27 39
-35 67 -35 63 0 109 58 88 112 -5 13 -43 58 -85 100 -82 84 -89 102 -60 163
21 46 66 67 132 62 41 -3 59 -10 79 -29z m-370 2 c57 -34 54 -132 -7 -183
l-28 -24 34 -27 c29 -23 34 -33 34 -70 0 -40 -4 -46 -46 -79 -55 -43 -102 -54
-161 -39 -24 7 -52 12 -63 12 -11 0 -20 6 -20 13 0 6 12 103 27 214 l28 203
85 0 c67 0 92 -4 117 -20z"/>
					<path d="M2695 4080 c-3 -5 -8 -39 -12 -75 l-6 -65 46 0 c57 0 92 15 106 47
17 38 13 60 -15 82 -28 22 -108 30 -119 11z m102 -40 c2 0 3 -11 3 -26 0 -19
-8 -28 -35 -40 -53 -22 -58 -19 -50 29 10 56 10 57 48 46 17 -5 33 -9 34 -9z"/>
					<path d="M2680 3893 c-25 -10 -32 -32 -32 -102 l0 -66 61 1 c69 1 105 24 114
72 7 35 -19 89 -46 96 -27 7 -77 6 -97 -1z m91 -53 c26 -14 24 -55 -3 -74 -12
-9 -38 -16 -56 -16 -33 0 -34 1 -28 31 3 17 6 40 6 50 0 15 7 19 31 19 17 0
39 -5 50 -10z"/>
					<path d="M4970 4170 c-156 -20 -238 -132 -228 -309 10 -161 88 -233 254 -235
71 -1 87 3 140 29 101 52 154 163 139 293 -20 166 -128 245 -305 222z m183
-56 c65 -46 92 -103 92 -195 0 -84 -11 -109 -73 -163 -19 -17 -41 -36 -47 -43
-24 -23 -108 -44 -149 -37 -81 13 -173 81 -194 143 -35 100 11 240 94 292 53
32 83 39 162 35 62 -3 80 -8 115 -32z"/>
					<path d="M4920 4078 c-19 -13 -37 -26 -38 -30 -2 -5 -9 -14 -16 -21 -21 -20
-36 -77 -36 -134 0 -126 100 -207 213 -173 64 19 97 48 125 108 45 99 15 214
-68 257 -45 23 -138 19 -180 -7z m167 -38 c36 -21 73 -85 73 -125 0 -58 -47
-132 -102 -161 -60 -31 -139 -6 -178 58 -48 79 -1 206 90 241 32 12 86 6 117
-13z"/>
					<path d="M5395 4168 c-22 -61 -135 -479 -135 -500 0 -27 1 -28 58 -28 l58 0
23 98 c13 53 27 104 32 112 8 16 7 20 59 -120 16 -41 31 -83 35 -92 4 -10 17
-18 29 -18 16 0 33 20 70 83 27 45 61 100 75 122 l26 40 5 -110 5 -110 53 -3
52 -3 0 82 c0 82 -16 337 -26 417 -5 37 -8 43 -27 40 -16 -2 -47 -45 -117
-160 -52 -87 -97 -158 -100 -158 -3 0 -33 71 -66 158 -51 128 -66 158 -83 160
-12 2 -23 -3 -26 -10z m78 -155 c27 -71 57 -150 67 -178 17 -47 40 -72 40 -44
0 14 200 352 205 347 2 -2 6 -57 10 -123 4 -66 10 -161 14 -212 4 -61 3 -89
-4 -85 -5 4 -18 5 -27 4 -16 -3 -18 8 -18 115 0 147 -8 163 -45 94 -16 -28
-32 -51 -37 -51 -5 0 -7 -4 -4 -9 4 -5 3 -11 -1 -13 -5 -1 -15 -16 -23 -33 -9
-16 -26 -35 -38 -41 -12 -6 -19 -14 -15 -17 3 -4 2 -7 -3 -8 -5 0 -19 -2 -32
-3 -27 -3 -56 29 -73 82 -15 49 -59 145 -63 141 -2 -2 -14 -42 -25 -89 -12
-47 -26 -101 -31 -120 -6 -23 -20 -41 -40 -52 -16 -9 -30 -14 -30 -9 0 4 20
77 44 162 24 85 49 180 56 212 7 31 16 57 19 57 4 0 28 -57 54 -127z"/>
					<path d="M4455 4156 c-49 -21 -94 -65 -123 -120 -24 -45 -27 -62 -27 -151 0
-88 3 -105 25 -145 52 -93 168 -136 294 -110 64 13 72 24 77 95 3 42 1 50 -15
53 -10 2 -37 -6 -58 -18 -102 -57 -217 22 -206 141 13 127 136 198 231 133 50
-34 70 -29 79 21 10 61 1 82 -43 99 -49 20 -191 21 -234 2z m228 -26 c28 -14
33 -26 19 -48 -7 -10 -16 -9 -43 5 -20 10 -55 16 -86 16 -106 -3 -183 -90
-183 -206 0 -75 22 -121 73 -155 50 -33 137 -37 181 -8 34 21 38 19 27 -20 -7
-27 -12 -30 -67 -36 -83 -11 -147 9 -203 64 -71 69 -87 133 -57 232 23 75 58
120 118 151 46 23 62 26 123 22 39 -2 82 -10 98 -17z"/>
					<path d="M3325 4148 c-13 -43 -37 -298 -34 -366 4 -73 7 -84 33 -111 40 -42
116 -58 203 -44 77 14 128 49 154 107 18 41 59 310 59 387 l0 39 -55 0 c-30 0
-55 -4 -55 -8 0 -30 -42 -303 -51 -329 -22 -67 -97 -102 -146 -68 -33 23 -35
54 -13 207 29 209 31 198 -35 198 -36 0 -57 -4 -60 -12z m83 -40 c-43 -312
-43 -307 -24 -343 32 -59 121 -71 179 -25 14 11 33 39 41 63 17 44 46 234 46
299 0 37 1 38 36 38 33 0 36 -2 30 -23 -3 -13 -15 -88 -26 -166 -11 -79 -24
-151 -30 -160 -11 -22 -81 -85 -110 -101 -59 -31 -169 -2 -214 57 -22 30 -23
84 -1 249 8 63 15 122 15 130 0 9 11 14 31 14 29 0 31 -2 27 -32z"/>
					<path d="M3800 4148 c0 -7 -14 -116 -30 -242 -17 -126 -30 -238 -30 -248 0
-16 11 -18 128 -18 146 0 182 12 214 71 22 41 24 129 3 169 -13 25 -13 32 0
57 9 16 15 55 15 93 0 69 -14 97 -57 119 -31 16 -243 15 -243 -1z m243 -36
c31 -30 40 -69 22 -105 -8 -15 -9 -24 -3 -21 22 14 -14 -37 -37 -52 l-24 -16
34 -33 c27 -25 35 -40 35 -68 0 -53 -7 -64 -67 -98 -51 -29 -60 -31 -116 -25
-34 3 -65 10 -70 15 -5 5 -16 7 -23 4 -9 -3 -14 2 -14 13 0 19 36 317 46 382
l5 34 94 -4 c81 -3 97 -6 118 -26z"/>
					<path d="M3876 4068 c-3 -13 -7 -47 -8 -76 l-3 -52 45 0 c56 0 91 17 108 52
25 53 -20 98 -98 98 -33 0 -40 -4 -44 -22z m102 -31 c24 -19 13 -47 -23 -63
-48 -20 -55 -18 -55 14 0 56 10 72 39 66 14 -4 32 -11 39 -17z"/>
					<path d="M3843 3886 c0 -6 -4 -20 -9 -30 -4 -11 -4 -17 2 -14 5 4 8 -21 6 -58
l-4 -64 44 1 c72 1 111 19 124 58 11 35 8 61 -13 96 -9 16 -23 19 -80 20 -44
1 -70 -2 -70 -9z m118 -46 c26 -14 24 -55 -3 -74 -12 -9 -38 -16 -57 -16 -33
0 -34 0 -27 38 3 20 6 43 6 50 0 15 54 16 81 2z"/>
					<path d="M4122 3748 c-16 -16 -16 -90 0 -106 7 -7 28 -12 48 -12 47 0 60 15
60 65 0 50 -13 65 -60 65 -20 0 -41 -5 -48 -12z m72 -24 c21 -21 5 -51 -26
-49 -38 3 -35 64 4 65 4 0 14 -7 22 -16z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
